//Looks for common errors and returns an object with those errors if they exist
export function getUpdatedPricingErrors(materialsToAdd, pricingCache, materialsCache) {
  var updatedPricingErrors = {};
  materialsToAdd.forEach(function (_ref) {
    var _materialsCache$mater;

    var materialNumber = _ref.materialNumber;
    var res = pricingCache.current[materialNumber];

    if (res !== null && res !== void 0 && res.loading || (_materialsCache$mater = materialsCache[materialNumber]) !== null && _materialsCache$mater !== void 0 && _materialsCache$mater.loading) {
      return;
    }

    var material = res === null || res === void 0 ? void 0 : res.data;

    if (!material) {
      updatedPricingErrors[materialNumber] = {
        errorMsg: 'UNABLE_TO_GET_RESPONSE_FROM_PI'
      };
    } else if (material.discontinuedPricingInfo) {
      updatedPricingErrors[material.productNumber] = material.discontinuedPricingInfo;
    } else if (material.materialPricing.length === 0 || !material.materialPricing.find(function (x) {
      return x.materialNumber === materialNumber;
    })) {
      updatedPricingErrors[material.productNumber] = {
        errorMsg: 'PRICING_AND_AVAILABILITY_UNAVAILABLE'
      };
    }
  });
  return updatedPricingErrors;
}