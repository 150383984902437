import dynamic from 'next/dynamic';
/**
 * Use dynamic import helper to disable SSR for this component, since the
 * `browserSupportsAdvancedUpload` function can only be run in the browser.
 */

var Upload = dynamic(function () {
  return import('./Upload');
}, {
  ssr: false,
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak('./Upload')];
    }
  }
});
export default Upload;